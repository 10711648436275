<template>
<div class="add-friend-btn-component AddFriendBtn">
    <button class="btn btn__round btn__bordered"
            :class="{'active': isFriend }"
            :disabled="loading"
            @click="addFriend">
        <div v-if="loading" class="spinner-border" />
        <i v-else-if="isFriend" class="icon-friends_right">
            <span class="path1" /><span class="path2" /><span class="path3" /><span class="path4" /><span class="path5" /><span class="path6" /><span class="path7" /><span class="path8" />
        </i>
        <i v-else class="icon-add-a-friend">
            <span class="path1" /><span class="path2" /><span class="path3" /><span class="path4" /><span class="path5" /><span class="path6" /><span class="path7" /><span class="path8" />
        </i>

        <span v-if="!short">{{ btnText }}</span>
    </button>
</div>
</template>

<script>
import { mapGetters } from "vuex";
import {addFriend} from "@api/api.friends";

    export default {
        name: "AddFriendBtn",
        props: {
            user: {type: Object, default: () => {}},
            short: {type: Boolean, default: false},
        },
        data() {
            return {
                loading: false,
                mouseover: false,
            }
        },
        computed: {
            ...mapGetters('auth', {
                currentUser: 'getCurrentUser'
            }),
            friendStatus() {
                return this.user.friend_status
            },
            isFriend() {
                return this.user.is_friend || this.friendStatus === 'approved'
            },
            btnText() {
                if (this.isFriend) {
                    return 'Connected'
                } else if (this.friendStatus === 'awaiting') {
                    return 'Approve'
                } else if (this.friendStatus === 'pending') {
                    return 'Pending'
                } else {
                    return 'Connect'
                }
            },
        },
        methods: {
            async addFriend() {
                this.loading = true
                const response = await addFriend({user_id: this.user.id })
                this.loading = false
                this.$toast.success(response.data.message)
                const { friend_status, is_friend } = response.data.response
                this.$emit('is_friend', is_friend)
                this.$emit('friend_status', friend_status)
            },
        }
    }
</script>

<style lang="scss" scoped>
    .add-friend-btn-component {
        font-size: 26px;
        .btn {
            white-space: nowrap;
            padding: 0 1.5rem;
            &.active {
                &:hover {
                    background: #e4e2f1;
                    border-color: #e4e2f1;
                    color: #4b419a;
                }
            }
            & > * {
                vertical-align: middle;
                line-height: 26px;
            }
            .icon-add-a-friend {
                font-size: 2.6rem;
            }
        }
        .spinner-border {
            opacity: 0.4;
        }
    }
</style>