import {
    getProfile,
    getUserCollections,
    getUserCards,
    getUserProducts,
    addFriend
} from '../../api/api.profile'
import {
    getPosts
} from '../../api/api.posts'
import Profile from '@models/Profile'
import Post from '@models/Post'
import {
    contentLike,
    userFollow,
    userUnfollow,
    userLike
} from '../../api/api.discover'

const getDefaultState = () => {
    return {
        posts_pagination: {
            page: 1,
            limit: 3
        },
        collections_pagination: {
            page: 1,
            limit: 6
        },
        cards_pagination: {
            page: 1,
            limit: 6
        },
        products_pagination: {
            page: 1,
            limit: 6
        },
        posts: [],
        collections: [],
        cards: [],
        products: []
    }
}

const profile = {
    namespaced: true,
    state: getDefaultState(),
    getters: {
        getCollections: state => state.collections,
        getCards: state => state.cards,
        getUserProducts: state => state.products,
    },
    mutations: {
        RESET_STATE(state) {
            Object.assign(state, getDefaultState())
        },
        UPDATE_POST_PAGINATION(state) {
            state.posts_pagination.page += 1
        },
        UPDATE_COLLECTIONS_PAGINATION(state) {
            state.collections_pagination.page += 1
        },
        UPDATE_CARDS_PAGINATION(state) {
            state.cards_pagination.page += 1
        },
        UPDATE_PRODUCTS_PAGINATION(state) {
            state.products_pagination.page += 1
        },
        PUSH_PRODUCTS(state, products) {
            if (products) {
                const new_products = products.filter((p) => !state.products.map((p) => p.id).includes(p.id))
                state.products = [...state.products, ...new_products]
            }
        },
        PUSH_COLLECTIONS(state, collections) {
            if (collections) {
                state.collections = [...state.collections, ...collections]
            }
        },
        PUSH_CARDS(state, cards) {
            if (cards) {
                state.cards = [...state.cards, ...cards]
            }
        },
        SET_CONTENT_LIKE (state, data) {
            state.cards[data.index].trusteds_count += data.value
            state.cards[data.index].card_tally_info.trusted = (data.value === 1)
        },
        RESET_CARDS_PAGINATION(state) {
            state.cards = []
            state.cards_pagination.page = 1
        },
        RESET_COLLECTIONS_PAGINATION(state) {
            state.collections = []
            state.collections_pagination.page = 1
        }
    },
    actions: {
        async GET_PROFILE({commit}, id) {
            const response = await getProfile(id)
            Profile.insertOrUpdate({data: response.data.response})
        },
        FLAG_PROFILE({commit}, data) {
            window.location = `/flags/Profile/${data.payload}`
        },
        async HANDLE_FOLLOW_USER({commit}, id) {
            const data = {
                user_id: id
            }
            const profile = Profile.find(id)
            let response
            if (profile.followed) {
                response = await userUnfollow(data)
            } else {
                response = await userFollow(data)
                
            }
            Profile.update({
                where: id,
                data: {
                    followed: response.data.response.is_following,
                    followers_count: response.data.response.followers
                }
            })
            return
            
        },
        async LIKE_USER ( {commit, state}, payload) {
            const response = await userLike(payload)
            const profile = Profile.find(payload.user_id)
            if (response.data.response.message === 'Elevate is removed') {
                Profile.update({
                    where: payload.user_id,
                    data: {
                        trusted: false,
                        trusteds_count: profile.trusteds_count -1 
                    }
                })
            } else {
                Profile.update({
                    where: payload.user_id,
                    data: {
                        trusted: true,
                        trusteds_count: profile.trusteds_count +1 
                    }
                })
            }
           
            
        },
        async ADD_FRINED({commit}, data) {
            const response = await addFriend(data)
            if (response.data.message === 'Connect request sent successfully.') {
                Profile.update({
                    where: data.right_id,
                    data: {
                        sent_friend_status: 'pending'
                    }
                })
            }
            return response
        },
        async GET_POSTS({commit, state}, payload) {
            const response = await getPosts(state.posts_pagination, 'users', payload.id)
            if (response.data.status === 200) {
                Post.insertOrUpdate({data: response.data.response})
            }
            if (response.data.current_page !== response.data.total_pages) {
                commit('UPDATE_POST_PAGINATION')
            }
            return response
        },
        async GET_USER_COLLECTIONS({commit, state}, payload) {
            const response = await getUserCollections(state.collections_pagination, payload.id, payload.keyword)
            if (response.data.status === 200) {
                commit('PUSH_COLLECTIONS', response.data.response)
            }
            if (response.data.current_page !== response.data.total_pages) {
                commit('UPDATE_COLLECTIONS_PAGINATION')
            }
            return response 
        },
        async GET_USER_CARDS({commit, state}, payload) {
            const response = await getUserCards(state.cards_pagination, payload.id, payload.keyword)
            if (response.data.status === 200) {
                commit('PUSH_CARDS', response.data.response)
            }
            if (response.data.current_page !== response.data.total_pages) {
                commit('UPDATE_CARDS_PAGINATION')
            }
            return response 
        },
        async LIKE_CONTENT ({commit, state}, payload) {
            const response = await contentLike(payload)
            const index = state.cards.findIndex(card => card.card_network_id === response.data.response.card_network_id)
            if (response.data.status === 200) {
                if ((state.cards[index].card_tally_info || {}).trusted === true) {
                    commit ('SET_CONTENT_LIKE', {index: index, value: -1})
                } else {
                    commit ('SET_CONTENT_LIKE', {index: index, value: 1})
                }
            }
            return response
        },
        async SEARCH_CONTENT({commit, state}, payload) {
            let responseContent
            if (payload.contentType === 'cards') {
                commit('RESET_CARDS_PAGINATION')
                const response = await getUserCards(state.cards_pagination, payload.id, payload.keyword)
                if (response.data.status === 200 && response.data.response) {
                    commit('PUSH_CARDS', response.data.response)
                }
                if (response.data.current_page !== response.data.total_pages) {
                    commit('UPDATE_CARDS_PAGINATION')
                }
                responseContent = response 
            }
            if (payload.contentType === 'collections') {
                commit('RESET_COLLECTIONS_PAGINATION')
                const response = await getUserCollections(state.collections_pagination, payload.id, payload.keyword)
                if (response.data.status === 200 && response.data.response) {
                    commit('PUSH_COLLECTIONS', response.data.response)
                }
                if (response.data.current_page !== response.data.total_pages) {
                    commit('UPDATE_COLLECTIONS_PAGINATION')
                }
                responseContent = response 
            }
            return responseContent
        },
        async GET_USER_PRODUCTS ({commit, state}, payload) {

            const response = await getUserProducts(state.products_pagination, payload.id)
            if (response.data.status === 200) {
                commit('PUSH_PRODUCTS', response.data.response)
            }
            if (response.data.current_page !== response.data.total_pages) {
                commit('UPDATE_PRODUCTS_PAGINATION')
            }
            return response 
        }
    }
}

export default profile