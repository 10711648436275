<template>
<div class="post-privacy">
    <vue-select class="privacy"
                placeholder="All Connections"
                :options="privacy_options"
                label="name"
                :reduce="option => option.value"
                :value="privacy"
                :clearable="false"
                :searchable="false"
                @input="$emit('change_privacy', $event)" />
</div>
</template>

<script>
    import VueSelect from 'vue-select'

    export default {
        components: { VueSelect },
        props: {
            privacy: {type: String, default: 'friends'}, //public|friends|friends_group
        },
        data() {
            return {
                privacy_options: [
                    {value: 'public', name: 'Public'},
                    {value: 'friends', name: 'All Connections'},
                    {value: 'friends_group', name: 'Connnections List'}], //
            }
        },
    }
</script>